export default {
  user: 'User',
  users: 'Users',
  archive: 'Archive',
  group_management: 'Group Management',
  subjects_management: 'Subjects Management',
  room_groups: 'Room Groups',
  id: 'Id',
  role: 'Role',
  name: 'Name',
  email: 'Email',
  phone: 'Phone',
  personal_code: 'Personal code',
  actions: 'Actions',
  room_status: 'Room disabled',
  user_was_updated: 'User was updated',
  user_was_created: 'User was created',
  user_was_removed: 'User was removed',
  user_were_removed: 'Users were removed',
  please_select_users: 'Please select users',
  please_select_groups: 'Please select groups',
  please_select_subjects: 'Please select subjects',
  delete: 'Delete',
  are_you_sure_you_want_to_delete_a_user: 'Are you sure you want to delete a user ?',
  are_you_sure_you_want_to_delete_a_users: 'Are you sure you want to delete the users ?',
  this_student_is_participant_of_active_event: 'This student is participant of active event',
  user_phone_number: 'User phone number',
  user_middlename: 'User Middlename',
  create_user: 'Create User',
  update_user: 'Update User',
  group_students: 'Group Students',
  edit_group_management: 'Edit Group Management',
  ready_to_attach_participants: 'Ready to attach participants',
  assigned_participants: 'Assigned participants',
  save_name: 'Save Name',
  group_was_updated: 'Group was updated',
  group_was_removed: 'Group was removed',
  assigned: 'Assigned',
  detach: 'Detach',
  students_were_assigned: 'Students were assigned',
  students_were_detached: 'Students were detached',
  are_you_sure_you_want_to_delete_a_group: 'Are you sure you want to delete a group ?',
  are_you_sure_you_want_to_delete_a_subject: 'Are you sure you want to delete a subject ?',
  subject_was_updated: 'Subject was updated',
  subject_was_created: 'Subject was created',
  subject_was_removed: 'Subject was removed',
  subject_were_removed: 'Subjects were removed',
  one_or_several_groups_was_removed_already_please_reload_page: 'One or several groups was removed already, please reload page',
  panel_title: 'Admin panel',
  room_group: 'Room Group',
  description: 'Description',
  edit_subjects: "Edit Subjects",
  create_subject: "Create Subject",
  edit_subject: "Edit Subject",
  search: "Search",
  cancel: "Cancel",
  submit: "Submit",
  confirm_delete_user: "Are you sure you want to delete this user?",
  confirm_delete_users: "Are you sure you want to delete these users?",
  no_data_available: "No data available",
  administrator: "Administrator",
  attendant: "Attendant",
  teacher: "Teacher",
  student: "Student",
  screen_view: "Screen View",
  all: "All",
  user_phone: "User Phone Number",
  select_role: "Select Role",
  user_status: "User Status",
  active: "Active",
  archived: "Archived",
  field_required: "Field is required",
  email_invalid: "Email field is not valid",
  user_updated: "User updated successfully",
  error_updating: "Error updating user",
  select_required: "Please select a value",
  phone_invalid: "Invalid phone number format",
  group_not_found: "Group not found",
  groups_were_removed: "Groups were removed",
  error_deleting_groups: "Error deleting groups",
  error_loading_students: "Error loading students",
  error_loading_group: "Error loading group",
  create_group: "Create Group",
  create_group_title: "Create Group",
  create_group_name: "Name",
  create_group_name_required: "Name is required",
  create_group_cancel: "Cancel",
  create_group_create: "Create",
  delete_title: "Delete",
  delete_group_confirmation: "Are you sure you want to delete a group?",
  delete_cancel: "Cancel",
  delete_submit: "Submit",
  delete_multiple_groups: "Please select groups", // for the batch delete error message
  search_placeholder: "Search...",
  delete_subject_confirmation: "Are you sure you want to delete a subject?",
  create_user: 'Create User',
  create_user_title: 'Create User',
  user_email: 'Email',
  user_name: 'Name',
  user_surname: 'Surname',
  user_middlename: 'Middle Name',
  user_personal_code: 'Personal Code',
  user_phone_number: 'Phone Number',
  select_role: 'Select Role',
  user_status: 'User Status',
  user_status_active: 'Active',
  user_status_archived: 'Archived',
  field_required: 'Field is required',
  email_invalid: 'Email field is not valid',
  cancel: 'Cancel',
  submit: 'Submit'
};
