const getters = {
    getRooms: (state) => state.rooms,
    getRoomsWithEvents: (state) => state.roomsWithEvents,
    isLoaded: (state) => state.isLoaded,
    getSelectedRooms: (state) => state.selectedRooms,
};

const actions = {
    setRooms({ commit }, rooms) {
        commit("SET_ROOMS", rooms);
    },
    setRoomsWithEvents({ commit }, { data }) {
        commit("SET_ROOMS_WITH_EVENTS", data);
    },
    setSelectedRooms({ commit }, rooms) {
        commit("SET_SELECTED_ROOMS", rooms);
    },
};

const mutations = {
    SET_ROOMS(state, rooms) {
        state.rooms = [
            ...rooms.map((roomGroup) => {
                // eslint-disable-next-line
                roomGroup.rooms = [
                    ...roomGroup.rooms.map((room) => {
                        // eslint-disable-next-line
                        //   console.log("window.app.$t('message.room')1111");
                        //   console.log(window.app.$t('message.room'));
                        //   console.log("window.app.$t('message.room')22222");
                        room.name = `${window.app.$t("message.room")}_${
                            room.room_number
                        }`;
                        // eslint-disable-next-line
                        room.key = `room_${room.id}`;
                        // room.disabled = room.is_available === 1 ? false : true;
                        room.is_available =
                            room.is_available === 1 ? true : false;
                        return room;
                    }),
                ];
                // eslint-disable-next-line
                roomGroup.key = `group_${roomGroup.id}`;
                return roomGroup;
            }),
        ];
        state.isLoaded = true;
    },
    SET_ROOMS_WITH_EVENTS(state, rooms) {
        console.log(rooms);
        state.roomsWithEvents = [...rooms];
    },
    SET_SELECTED_ROOMS(state, rooms) {
        state.selectedRooms = [...rooms];
    },
};

const state = {
    isLoaded: false,
    rooms: [],
    roomsWithEvents: [],
    selectedRooms: [],
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
