import store from '@store';

const configRoles = {
  admin: 'administrator',
};

export default {
  checkRoles(role) {
    const user = store.getters['auth/getUser'];
    if (!user?.data?.roles) return false;

    const userRoles = user?.data?.roles;

    return userRoles.some(item => item.name === role);
  },
  isAdmin() {
    return this.checkRoles(configRoles.admin);
  },
};
