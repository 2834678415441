// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
// import { VTreeview } from 'vuetify/labs/VTreeview'
import * as labsComponents from "vuetify/labs/components";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import store from "./store";
import { useI18n } from 'vue-i18n';  // Import useI18n
import { lv, en } from 'vuetify/locale'

const customeTheme = {
    dark: false,
    colors: {
        primary: "#673AB7",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        lightblue: "#14c6FF",
        yellow: "#FFCF00",
        pink: "#FF1976",
        orange: "#FF8657",
        magenta: "#C33AFC",
        darkblue: "#1E2D56",
        gray: "#909090",
        neutralgray: "#9BA6C1",
        green: "#2ED47A",
        red: "#FF5c4E",
        darkblueshade: "#308DC2",
        lightgray: "#BDBDBD",
        lightpink: "#FFCFE3",
        white: "#FFFFFF",
        muted: "#6c757d",
    },
};

const selectedLanguage = store.getters["language/getSelectedLanguage"] || "en";
const currentLocale = selectedLanguage === "English" || selectedLanguage === "en" ? "en" : "lv";

const vuetify = createVuetify({
    components: {
        ...components,
        ...labsComponents,
    },
    locale: {
        locale: 'lv', // Default locale
        fallback: 'lv', // Fallback locale
        messages: { lv, en }, // Available locales
        t: (key, ...params) => {
            // Adjust the key to include the 'message' namespace
            if (key.startsWith('$vuetify.')) {
                key = '$message.' + key.substring(1);
            }
            return key;  // This will be replaced with i18n's t function during runtime
        }
    },
});

export default vuetify;
