<template>

    <v-select class="mr-4" id="category" v-model="selectedLanguage" :items="languageOptions" variant="outlined">
    </v-select>

</template>

<script>
import { mapActions, mapGetters } from "vuex";


export default {
    components: {
    },
    data: () => ({
        disableLogout: false,
    }),
    methods: {
        ...mapActions("language", ["initializeLanguages", "updateSelectedLanguage"]),

        changeLanguage(language) {
            this.updateSelectedLanguage(language);
        },
    },
    computed: {
        ...mapGetters("language", ["getLanguages", "getSelectedLanguage"]),
        languageOptions() {
            return this.getLanguages;
        },
        selectedLanguage: {
            get() {
                return this.getSelectedLanguage;
            },
            set(value) {
                this.changeLanguage(value);
            },
        },
    },

    created() {
        this.initializeLanguages(); // Initialize languages on component creation
    },
};
</script>

<style lang="scss" scoped></style>
