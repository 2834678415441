import "./bootstrap";

import { createApp } from "vue";
import Notifications from "@kyvg/vue3-notification";
import VCalendar from "v-calendar";
import "v-calendar/style.css";
import { createI18n } from "vue-i18n";
import api from "./api";
import store from "./store";
import router from "./router";
import vuetify from "./vuetify";
import globalMixin from "./mixins/global";
// import { Ripple } from 'vuetify/directives';
import App from "./App.vue";
import messages from "./lang";

// Set the default locale to Latvian
const defaultLocale = "lv"; 

// User selected language can be different for other parts of the app
// IMPORTANT: The store uses full language names ("English", "Latviešu"), not codes ("en", "lv")
const selectedLanguage = store.getters["language/getSelectedLanguage"] || "Latviešu";

// Convert the full language name to the locale code
const locale = selectedLanguage === "English" ? "en" : "lv";

const i18n = createI18n({
    locale: locale,
    fallbackLocale: "lv",
    messages: messages,
});
import moment from "moment";
// import { DragonDropVue } from 'dragon-drop-vue'
// const dragonDropOptions = {
//     dragOverDebounceMs: 300,
//     debugLog: true,
// }

const Vue = createApp(App);
// Update moment locale using the same conversion
moment.locale(selectedLanguage === "English" ? "en" : "lv");

// Fix the problem with watches by ensuring store and plugin initialization happens in correct order
Vue.config.productionTip = false;
Vue.config.globalProperties.$moment = moment;
Vue.config.globalProperties.$api = api;

// Add missing global properties for authentication and permissions
Vue.config.globalProperties._$can = function(permission) {
    // Get permissions from store or use a default implementation
    const userPermissions = store.getters["auth/getUserPermissions"] || [];
    return Array.isArray(userPermissions) && userPermissions.includes(permission);
};

Vue.config.globalProperties.getIsAuthenticated = function() {
    return store.getters["auth/isAuthenticated"] || false;
};

// Apply plugins in a specific order
Vue.use(Notifications);

// KEEP ONLY ONE INSTANCE OF VCalendar registration - remove the duplicate
Vue.use(VCalendar, {
    componentPrefix: "vc",
    
});

Vue.use(i18n);  // Apply i18n before store to ensure translations are available
Vue.use(router);
Vue.use(store);  // Apply store after i18n is configured

// Apply global mixin to provide methods to all components
Vue.mixin(globalMixin);

// Update Vuetify's translation function to use i18n
vuetify.locale.t = (key, ...params) => {
    // If key starts with $vuetify, modify it to look in message.vuetify
    if (key.startsWith('$vuetify.')) {
        return i18n.global.t('message.vuetify.' + key.substring(9), params);
    }
    return i18n.global.t(key, params);
};

Vue.use(vuetify);
// Vue.use(DragonDropVue, dragonDropOptions);

// Also update watch for language changes to include VCalendar locale
store.watch(
    (state, getters) => getters["language/getSelectedLanguage"],
    (newLanguage) => {
        if (!newLanguage) return;
        
        // Convert the full language name to locale code
        const newLocale = newLanguage === "English" ? "en" : "lv";
        
        i18n.global.locale = newLocale;
        moment.locale(newLocale);
        vuetify.locale.current.value = newLocale;
    }
);

window.app = Vue.mount("#app");

// console.log('--------------------------------');
// console.log(window.app.$t('message.room'));
// console.log('--------------------------------');
