export default {
    namespaced: true,
    state: () => ({
        useStatusColors: true, // Default to status-based colors
    }),
    mutations: {
        SET_COLOR_SCHEME(state, useStatusColors) {
            state.useStatusColors = useStatusColors;
        }
    },
    actions: {
        setColorScheme({ commit }, useStatusColors) {
            //console.log('Setting color scheme in store to:', useStatusColors ? 'Status' : 'Category');
            commit('SET_COLOR_SCHEME', useStatusColors);
        }
    },
    getters: {
        getColorScheme: state => state.useStatusColors
    }
}; 