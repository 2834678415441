const getters = {
  getGroups: state => state.groups,
  getStudents: state => state.students,
  isLoading: state => state.loading,
};

const actions = {
  setLoading({ commit }, payload) {
    commit('setLoading', payload);
  },
  setGroups({ commit }, payload) {
    commit('setGroups', payload);
  },
  setStudents({ commit }, payload) {
    commit('setStudents', payload);
  },
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setGroups(state, groups) {
    state.groups = groups;
  },
  setStudents(state, students) {
    state.students = students;
  },
};

const state = {
  loading: false,
  groups: [],
  students: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
