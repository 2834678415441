<template>
  <v-app>
    <Header v-if="getIsAuthenticated && !isAdminPanel" />
    <router-view />
    <notifications group="global" position="top right" />
    <ConfirmModal ref="confirm" />
  </v-app>
</template>

<script>
import Header from '@components/Header/Header.vue';
import ConfirmModal from '@modals/ConfirmModal.vue';
import store from "./store";
export default {
  name: 'App',
  components: {
    Header,
    ConfirmModal,
  },
  async created() {
    const selectedLanguage = store.getters["language/getSelectedLanguage"] || "en";
    selectedLanguage === "English" || selectedLanguage === "en" ? "en" : "lv"

    this.$moment.locale(selectedLanguage);
    // this.$moment.locale(this.$store.getters['global/getLocale']);
    this.fetchOnCreate();
  },
  /* TODO fix Header */
  computed: {
    isAdminPanel() {
      //console.log('isAdminPanel');
      return this.$route.path.indexOf('admin') > -1;
    },
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm;
  },
  methods: {
    fetchOnCreate() {
      //console.log('fetchOnCreate', this.getIsAuthenticated);
      if (this.getIsAuthenticated) {
        //console.log('  if (this.getIsAuthenticated) {');
        this.$store.dispatch('auth/auth');
        this.$store.dispatch('auth/permissions');
        this.$api.rooms.fetchData();
        this.$api.teachers.fetchData();
        this.$api.students.fetchData();
        this.$api.subjects.fetchData();
        this.$api.categories.fetchData();
        this.$api.statuses.fetchData();
        this.$api.roles.getRoles();
      }
    },
  },
};
</script>

<style lang="scss">
@import "https://fonts.googleapis.com/icon?family=Material+Icons";
@import "./styles/style.scss";
@import 'vuetify/styles';

body {
  font-family: $defaultFont;
  //#nprogress {
  //  .bar{
  //    background: $accentColor;
  //  }
  //  .peg {
  //    box-shadow: 0 0 10px #f88d4c, 0 0 5px #f88d4c;
  //  }
  //}
}
</style>
