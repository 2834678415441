export default {
  user: 'Lietotājs',
  users: 'Lietotāji',
  archive: 'Arhīvs',
  group_management: 'Grupu pārvaldība',
  subjects_management: 'Priekšmetu pārvaldība',
  room_groups: 'Telpu grupas',
  id: 'ID',
  role: 'Loma',
  name: 'Vārds',
  email: 'E-pasts',
  phone: 'Tālrunis',
  personal_code: 'Personas kods',
  actions: 'Darbības',
  room_status: 'Telpa bloķēta',
  user_was_updated: 'Lietotājs tika atjaunināts',
  user_was_created: 'Lietotājs tika izveidots',
  user_was_removed: 'Lietotājs tika dzēsts',
  user_were_removed: 'Lietotāji tika dzēsti',
  please_select_users: 'Lūdzu, izvēlieties lietotājus',
  please_select_groups: 'Lūdzu, atlasiet grupas',
  please_select_subjects: 'Lūdzu, atlasiet priekšmetus',
  delete: 'Dzēst',
  are_you_sure_you_want_to_delete_a_user: 'Vai tiešām vēlaties dzēst lietotāju?',
  are_you_sure_you_want_to_delete_a_users: 'Vai tiešām vēlaties dzēst lietotājus?',
  this_student_is_participant_of_active_event: 'Šis skolēns piedalās aktīvā notikumā',
  user_phone_number: 'Lietotāja tālruņa numurs',
  user_middlename: 'Lietotāja otrais vārds',
  create_user: 'Izveidot lietotāju',
  update_user: 'Atjaunināt lietotāju',
  group_students: 'Grupu skolēni',
  edit_group_management: 'Rediģēt grupu',
  ready_to_attach_participants: 'Brīvie dalībnieki',
  assigned_participants: 'Piesaistītie dalībnieki',
  save_name: 'Saglabāt',
  group_was_updated: 'Grupa tika atjaunināta',
  group_was_removed: 'Grupa tika dzēsta',
  assigned: 'Piešķirt',
  detach: 'Atvienot',
  students_were_assigned: 'Skolēni tika piešķirti',
  students_were_detached: 'Skolēni tika atvienoti',
  are_you_sure_you_want_to_delete_a_group: 'Vai tiešām vēlaties dzēst grupu?',
  are_you_sure_you_want_to_delete_a_subject: 'Vai tiešām vēlaties dzēst priekšmetu?',
  subject_was_updated: 'Priekšmets tika atjaunināts',
  subject_was_created: 'Priekšmets tika izveidots',
  subject_was_removed: 'Priekšmets tika dzēsts',
  subject_were_removed: 'Priekšmeti tika dzēsti',
  one_or_several_groups_was_removed_already_please_reload_page: 'Viena vai vairākas grupas jau ir dzēstas, lūdzu atjauniniet lapu',
  panel_title: 'Administrācijas panelis',
  room_group: 'Telpu grupa',
  description: 'Apraksts',
  edit_subjects: "Rediģēt priekšmetus",
  create_subject: "Izveidot priekšmetu",
  edit_subject: "Rediģēt priekšmetu",
  search: 'Meklēt',
  cancel: 'Atcelt',
  submit: 'Apstiprināt',
  confirm_delete_user: 'Vai tiešām vēlaties dzēst šo lietotāju?',
  confirm_delete_users: 'Vai tiešām vēlaties dzēst šos lietotājus?',
  no_data_available: 'Nav pieejamu datu',
  administrator: 'Administrators',
  attendant: 'Dežurants',
  teacher: 'Skolotājs',
  student: 'Students',
  screen_view: 'Ekrāna skats',
  all: 'Visi',
  user_middlename: "Otrais vārds",
  user_phone: "Tālruņa numurs",
  select_role: "Izvēlēties lomu",
  user_status: "Lietotāja statuss",
  active: "Aktīvs",
  archived: "Arhivēts",
  field_required: "Lauks ir obligāts",
  email_invalid: "E-pasta lauks nav derīgs",
  user_updated: "Lietotājs veiksmīgi atjaunināts",
  error_updating: "Kļūda atjauninot lietotāju",
  select_required: "Lūdzu izvēlieties vērtību",
  phone_invalid: "Nederīgs tālruņa numura formāts",
  group_not_found: "Grupa nav atrasta",
  groups_were_removed: "Grupas tika dzēstas",
  error_deleting_groups: "Kļūda dzēšot grupas",
  error_loading_students: "Kļūda ielādējot studentus",
  error_loading_group: "Kļūda ielādējot grupu",
  create_group: "Izveidot grupu",
  create_group_title: "Izveidot grupu",
  create_group_name: "Nosaukums",
  create_group_name_required: "Nosaukums ir obligāts",
  create_group_cancel: "Atcelt",
  create_group_create: "Izveidot",
  delete_title: "Dzēst",
  delete_group_confirmation: "Vai tiešam vēlaties dzēst grupu?",
  delete_cancel: "Atcelt",
  delete_submit: "Apstiprināt",
  delete_multiple_groups: "Lūdzu, izvēlieties grupas", // for the batch delete error message
  search_placeholder: "Meklēt...",
  delete_subject_confirmation: "Vai tiešam vēlaties dzēst priekšmetu?",
  create_user: "Izveidot lietotāju",
  create_user_title: "Izveidot lietotāju",
  user_email: "E-pasts",
  user_name: "Vārds",
  user_surname: "Uzvārds",
  user_middlename: "Otrais vārds",
  user_personal_code: "Personas kods",
  user_phone_number: "Tālruņa numurs",
  select_role: "Izvēlēties lomu",
  user_status: "Lietotāja statuss",
  user_status_active: "Aktīvs",
  user_status_archived: "Arhivēts",
  field_required: "Lauks ir obligāts",
  email_invalid: "E-pasta lauks nav derīgs",
  cancel: "Atcelt",
  submit: "Saglabāt"
}; 