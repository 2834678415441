import { createStore } from "vuex";

/* ----------   Other   ----------*/
import plugins from "@store/plugins/index";
import auth from "@store/modules/auth";
import categories from "@store/modules/categories";
import eventForm from "@store/modules/eventForm";
import global from "@store/modules/global";
import group from "@store/modules/group";
import groups from "@store/modules/groups";
import individualPlan from "@store/modules/individualPlan";
import modalConflictEvents from "@store/modules/modalConflictEvents";
import password from "@store/modules/password";
import roles from "@store/modules/roles";
import rooms from "@store/modules/rooms";
import roomSchedule from "@store/modules/roomSchedule";
import statuses from "@store/modules/statuses";
import students from "@store/modules/students";
import subject from "@store/modules/subject";
import subjects from "@store/modules/subjects";
import teachers from "@store/modules/teachers";
import user from "@store/modules/user";
import users from "@store/modules/users";
import language from "@store/modules/language";
import calendar from './modules/calendar';

export default createStore({
    modules: {
        auth,
        categories,
        eventForm,
        global,
        group,
        groups,
        individualPlan,
        modalConflictEvents,
        password,
        roles,
        rooms,
        roomSchedule,
        statuses,
        students,
        subject,
        subjects,
        teachers,
        user,
        users,
        language,
        calendar
    },
    plugins,
});
