// messages.js
import admin from "./admin.js";

export default {
    room_schedule: "Stundu saraksts",
    teachersAndStudents: "Skolotāji un skolēni",
    individual_plan: "Individuālais plāns",
    monday: "Pirmdiena",
    thuesday: "Otrdiena",
    wednesday: "Trešdiena",
    thursday: "Ceturtdiena",
    friday: "Piektdiena",
    saturday: "Sestdiena",
    sunday: "Svētdiena",
    open: "Atvērt",
    search: "Meklēt",
    room: "Telpa",
    rooms: "Telpas",
    students: "Skolnieki",
    status: "Statuss",
    teachers: "Skolotājs",
    name: "Nosaukums",
    event: "Notikums",
    eventName: "Notikuma nosaukums",
    category: "Kategorija",
    select: "Izvēlēties",
    location: "Telpa",
    period: "Periodi",
    single: "Vienreizējs",
    recurring: "Ar atkārtojumu",
    date: "Datums",
    from: "No",
    until: "Līdz",
    to: "To",
    create: "Izveidot",
    edit: "Labot",
    cancel: "Atcelt",
    save: "Saglabāt",
    delete: "Dzēst",
    time: "Laiks",
    ohSorry: "Atvainojamies, ir kļūda....",
    goBackToUpdate: "Atgriezties, lai labotu",
    fieldIsRequired: "Lauks ir jāaizpilda",
    attendantIsRequired: "Jāizvēlās vismaz viens skolēns vai skolotājs",
    fieldIsNotValid: "Lauks nav derīgs",
    january: "Janvāris",
    february: "Februāris",
    march: "Marts",
    april: "Aprīls",
    may: "Maijs",
    june: "Jūnijs",
    jule: "Jūlijs",
    august: "Augusts",
    september: "Septembris",
    october: "Oktobris",
    november: "Novembris",
    december: "Decembris",
    weekly: "iknedēļas",
    everySecondWeek: "Katru otro nedēļu",
    everyThirdWeek: "Katru trešo nedēļu",
    monthly: "Katru mēnesi",
    add: "Pievienot",
    saveAll: "Saglabātu visu",
    saveClose: "Saglabāt un aizvērt ",
    dataCanNotBeSaved: "Dati netiks saglabāti. Vai vēlaties turpināt?",
    doYouWantToChangeAListRecurrence:
        "Vai vēlaties mainīt VISUS notikumus ar atkārtojumu?",
    doYouReallyWantToDeleteEvents: "Vai tiešām vēlaties dzēst notikumus?",
    inRoom: "telpā",
    lessonShouldEndBefore9Pm: "Mācību stundai jābeidzas pirms plkst. 21:00.",
    roomReservationError: "Telpa ir aizņemta šajā datumā.",
    studentReservationError:
        "Students/i ir aizņemti, lūdzu izvēlieties citu laiku.",
    studentReservationNoStudentsInGroupError: "Atlasītajās grupās nav skolēnu",
    teacherReservationError:
        "Skolotājs/i ir aizņemti, lūdzu, izvēlieties citu laiku.",
    subjectRequiredError: "Priekšmeta lauks ir obligāts.",
    dateFrom: "Datums no",
    dateUntil: "Datums līdz",
    timeFrom: "Laiks no",
    timeTo: "Laiks līdz",
    description: "Apraksts",
    eventDescription: "Notikuma apraksts",
    minTime: "Laikam vajadzētu būt pēc",
    maxTime: "Laikam vajadzētu būt iepriekš",
    // new words
    signIn: "Pieslēdzieties VMV",
    signUp: "Pieteikties",
    signOut: "Iziet",
    submit: "Iesniegt",
    email: "Epasts",
    password: "Parole",
    newPassword: "Jauna parole",
    resetPassword: "Jaunas paroles uzstādīšana",
    forgotPassword: "Aizmirsāt paroli?",
    emailError: "Epasta adrese nav pareiza!",
    passwordError: "6-10 zīmes",
    login: "Ienākt",
    confirmPassword: "Apstipriniet paroli",
    passwordNotMatch: "Paroles nesakrīt",
    surname: "Uzvārds",
    loginSuccess: "Pieslēgšanās ir veiksmīga.",
    loginFail: "Neizdevās pieslēgties, lūdzu, mēģiniet vēlreiz!",
    registerSuccess: "Reģistrācija ir veiksmīga.",
    emailWasSentSuccessful: "Epasts ir nosūtīts, lūdzu, pārbaudiet.",
    passwordResetSuccessful: "Parole ir veiksmīgi atjaunota.",
    signInByEclass: "Ienākt ar E-klasi",
    reservations: "Rezervācijas",
    selectClass: "Atlasīt klases laika posmu",
    subject: "Priekšmets",
    noRoomsSelectedGreeting: "Sveicināti telpu rezervācijas sistēmā!",
    noRoomsSelectedInstruction: "Lūdzu, izvēlieties telpu.",
    vuetify: {
        open: "Atvērt",
        close: "Aizvērt",
        dataIterator: {
            rowsPerPageText: "Vienumi lapā:",
            rowsPerPageAll: "Visi",
            pageText: "{0}-{1} no {2}",
            noResultsText: "Nav atrasti atbilstoši ieraksti",
            nextPage: "Nākamā lapa",
            prevPage: "Iepriekšējā lapa",
            loadingText: 'Ielādē...',
            noResultsText: 'Nekas netika atrasts'
        },
        dataTable: {
            rowsPerPageText: "Rindas lapā:"
        },
        noDataText: "Nav pieejamu datu",
        carousel: {
            prev: "Iepriekšējais vizuālais",
            next: "Nākamais vizuālais",
            ariaLabel: {
                delimiter: "Carousel slide {0} of {1}"
            }
        },
        calendar: {
            moreEvents: "More {0}",
            status_colors: "Status Colors",
            category_colors: "Category Colors"
        },
        fileInput: {
            counter: "{0} faili",
            counterSize: "{0} faili ({1} kopā)"
        },
        timePicker: {
            am: "AM",
            pm: "PM"
        },
        pagination: {
            ariaLabel: {
                root: 'Navigācija pa lapām',
                next: 'Nākamā lapa',
                previous: 'Iepriekšējā lapa',
                page: 'Iet uz lapu {0}',
                currentPage: 'Pašreizējā lapa, {0} lapa',
                first: 'Pirmā lapa',
                last: 'Pēdējā lapa'
            }
        },
        input: {
            clear: "Notīrīt",
            appendAction: "Pievienot darbību",
            prependAction: "Priekšgalā darbību"
        },
        datePicker: {
            title: "Izvēlieties datumu",
            header: "Izvēlieties",
            itemsSelected: "{0} izvēlēts",
            nextMonth: "Nākamais mēnesis",
            prevMonth: "Iepriekšējais mēnesis",
            moreEvents: "Vēl {0}"
        }
    },
    admin: {
        ...admin,
        no_data_available: "Nav pieejamu datu",
        search: "Meklēt",
        all: "Visi"
    },
    calendar: {
        room_not_available: 'Telpa nav pieejama rezervācijai.',
        unknown_event: 'Nezināms notikums',
        reservation: 'Rezervācija',
        concert: 'Koncerts',
        maintenance: 'Apkope',
        consultation: 'Konsultācija',
        other: 'Cits',
        status_colors: "Statusa krāsas",
        category_colors: "Kategorijas krāsas"
    }
};
