const getters = {
    getData(state) {
        return state.form;
    },
    getRecurringEvents(state) {
        return state.recurringEvents;
    },
    getRecurringData(state) {
        return state.recurringSaveData;
    },
    getIsRecurringOnCreating(state) {
        return state.isRecurringOnCreating;
    },
    getIsFormChanged(state) {
        return state.formChanged;
    },
};

const actions = {
    setData({ commit }, form) {
        commit("SET_DATA", form);
    },
    setRecurringEvents({ commit }, data) {
        commit("SET_RECURRING_EVENTS", data);
    },
    setNewEventData({ commit }, data) {
        commit("SET_NEW_EVENT_DATA", data);
    },
    setIsRecurringEventOnCreating({ commit }, value) {
        commit("SET_IS_RECURRING_EVENT_ON_CREATING", value);
    },
    setFormChanged({ commit }, value) {
        commit("SET_FORM_CHANGED", value);
    },
    saveRecurringData({ commit }, data) {
        commit("SAVE_RECURRING_DATA", data);
    },
    removeRecurringEvents({ commit }, ids) {
        commit("REMOVE_RECURRING_EVENTS", ids);
    },
    clearData({ commit }) {
        commit("CLEAR_DATA");
    },
};

const mutations = {
    SET_DATA(state, form) {
        state.form = {
            ...form,
            students: [
                ...form.students.map((item) => {
                    // eslint-disable-next-line
                    item.key = `student_${item.id}`;
                    return item;
                }),
            ],
        };
    },
    CLEAR_DATA(state) {
        state.form = { ...state.defaultForm };
        state.recurringEvents = [];
        state.recurringSaveData = {};
    },
    SET_RECURRING_EVENTS(state, data) {
        state.recurringEvents = [...data];
    },
    SET_NEW_EVENT_DATA(state, { id, room, start_datetime: startDateTime }) {
        state.recurringEvents = [
            ...state.recurringEvents.map((item) =>
                item.id === id
                    ? {
                          ...item,
                          room: { ...room },
                          start_datetime: startDateTime,
                      }
                    : item
            ),
        ];
    },
    SAVE_RECURRING_DATA(state, { form, recurringFormData }) {
        state.recurringSaveData = {
            form: { ...form },
            recurringFormData: { ...recurringFormData },
        };
    },
    REMOVE_RECURRING_EVENTS(state, ids) {
        state.recurringEvents = [
            ...state.recurringEvents.filter(
                (item) => !ids.some((id) => item.id === id)
            ),
        ];
    },
    SET_IS_RECURRING_EVENT_ON_CREATING(state, value) {
        state.isRecurringOnCreating = value;
    },
    SET_FORM_CHANGED(state, value) {
        state.formChanged = value;
    },
};

const state = {
    form: {
        id: null,
        event_name: "",
        category_id: "",
        status_id: 1,
        subject_id: "",
        room_id: "",
        teachers: [],
        students: [],
        start_time: "",
        end_time: "",
        date_reservation: "",
        errors: {
            teachers: {},
            students: {},
            room: "",
        },
    },
    defaultForm: {
        id: null,
        event_name: "",
        category_id: "",
        status_id: 1,
        subject_id: "",
        room_id: "",
        teachers: [],
        students: [],
        start_time: "",
        end_time: "",
        date_reservation: "",
        errors: {
            teachers: {},
            students: {},
            room: "",
        },
    },
    recurringEvents: [],
    recurringSaveData: {},
    isRecurringOnCreating: false,
    formChanged: false,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
