import http from "@utils/http";
import moment from "moment";
import store from "@store";
import conts from "@/constants";
import HelpersErrors from "@helpers/errors";

export default {
    getSchedule(
        dateFrom = store.getters["roomSchedule/getCurrentDate"].format(
            conts.backendDateFormat
        ),
        errors = store.getters["modalConflictEvents/getErrors"],
        rooms = store.getters["rooms/getSelectedRooms"],
        dateTo = rooms.length === 1
            ? moment(dateFrom, conts.backendDateFormat)
                  .add(conts.addDays, "days")
                  .format(conts.backendDateFormat)
            : dateFrom
    ) {
        return new Promise((resolve, reject) => {
            if (rooms.length === 0) {
                return;
            }

            store.dispatch("global/setAppLoading", true);

            // Determine the endpoint based on the number of selected rooms
            const endpoint =
            rooms.length === 1
                ? "/api/rooms/get_schedule_for_week"
                : "/api/rooms/get_schedule";

            http.get(endpoint, {
                params: {
                    rooms: [...rooms.map((room) => room.id)],
                    date_from: dateFrom,
                    date_to: dateTo,
                },
            })
                .then((res) => {
                    store.dispatch(
                        "roomSchedule/setSelectedRooms",
                        res.data.data
                    );
                })
                .catch((error) => {
                    // HelpersErrors.showErrors(error.response.data);

                    const previousDate =
                        store.getters["roomSchedule/getPreviousDate"];
                    if (previousDate) {
                        store.dispatch(
                            "roomSchedule/setCurrentDate",
                            previousDate
                        );
                    }

                    const prepareObject = {
                        errors: {
                            ...errors,
                            max_reservation_days: error?.response?.data?.error,
                            teachers: errors.no_active_teachers,
                            students: errors.no_active_students,
                            subject_id: errors.subject_id,
                        },
                    };
                    store.dispatch("modalConflictEvents/setShow", true);
                    store.dispatch(
                        "modalConflictEvents/setErrors",
                        prepareObject
                    );
                    reject(error);
                })
                .finally(() => {
                    store.dispatch("global/setAppLoading", false);
                });
        });
    },
};
