import http from '@utils/http';
import store from '@store';
import HelpersErrors from '@helpers/errors';

export default {
  getUser(id) {
    return new Promise((resolve, reject) => {
      store.dispatch('user/setLoading', true);
      http.get(`/api/admin/users/${id}`).then((res) => {
        store.dispatch('user/setUser', res.data.data);
        resolve(res.data);
      }).catch((error) => {
        HelpersErrors.showErrors(error.response.data.errors);
        reject(error);
      }).finally(() => store.dispatch('user/setLoading', false));
    });
  },
  getUsers(params) {
    return new Promise((resolve, reject) => {
      store.dispatch('users/setLoading', true);
      http.get('/api/admin/users', { params: { ...params, with: 'user_roles' } }).then((res) => {
        store.dispatch('users/setUsers', res.data);
        resolve(res.data);
      }).catch((error) => {
        HelpersErrors.showErrors(error.response.data.errors);
        reject(error);
      }).finally(() => store.dispatch('users/setLoading', false));
    });
  },
  updateUsers(params, userId) {
    return new Promise((resolve, reject) => {
      store.dispatch('user/setLoading', true);
      http.patch(`/api/admin/users/user/${userId}`, params).then((res) => {
        window.app.$notify({
          group: 'global',
          type: 'success',
          text: window.app.$t('message.admin.user_was_updated'),
        });
        resolve(res.data);
      }).catch((error) => {
        HelpersErrors.showErrors(error.response.data.errors);
        reject(error);
      }).finally(() => store.dispatch('user/setLoading', false));
    });
  },
  createUsers(params) {
    return new Promise((resolve, reject) => {
      store.dispatch('user/setLoading', true);
      http.post('/api/admin/users/user/create', params).then((res) => {
        window.app.$notify({
          group: 'global',
          type: 'success',
          text: window.app.$t('message.admin.user_was_created'),
        });
        resolve(res.data);
      }).catch((error) => {
        HelpersErrors.showErrors(error.response.data.errors);
        reject(error);
      }).finally(() => store.dispatch('user/setLoading', false));
    });
  },
  removeUser(id, confirmDeleteDialog,confirmSureDeleteDialog) {
    return new Promise((resolve, reject) => {
      store.dispatch('users/setLoading', true);
      http.delete(`/api/admin/users/user/${id}`).then((res) => {
        window.app.$notify({
          group: 'global',
          type: 'success',
          text: window.app.$t('message.admin.user_was_removed'),
        });
        resolve(res.data);
      }).catch((error) => {
        if (!error.response.data.isEvent) {
          HelpersErrors.showErrors(error.response.data.errors);
        }else{
          confirmDeleteDialog.value = false
          confirmSureDeleteDialog.value = true
        }

        reject(error);
      }).finally(() => store.dispatch('users/setLoading', false));
    });
  },

  removeSureUser(id, confirmDeleteDialog,confirmSureDeleteDialog) {
    return new Promise((resolve, reject) => {
      store.dispatch('users/setLoading', true);
      http.delete(`/api/admin/users/user/${id}?force=true`).then((res) => {
        window.app.$notify({
          group: 'global',
          type: 'success',
          text: window.app.$t('message.admin.user_was_removed'),
        });
        confirmSureDeleteDialog.value = false
        resolve(res.data);
      }).catch((error) => {
        if (!error.response.data.isEvent) {
          HelpersErrors.showErrors(error.response.data.errors);
        }

        reject(error);
      }).finally(() => store.dispatch('users/setLoading', false));
    });
  },

  removeMultiplyUser(params,confirmDeleteDialog, confirmSureDeleteDialog) {
    console.log(params,"params")
    return new Promise((resolve, reject) => {
      store.dispatch('users/setLoading', true);
      http.delete('/api/admin/users/user/multiply', { params }).then((res) => {
        window.app.$notify({
          group: 'global',
          type: 'success',
          text: window.app.$t('message.admin.user_were_removed'),
        });
        resolve(res.data);
      }).catch((error) => {
        if (!error.response.data.isEvent) {
          HelpersErrors.showErrors(error.response.data.errors);
        }else{
          confirmDeleteDialog.value = false
          confirmSureDeleteDialog.value = true
        }
        reject(error);
      }).finally(() => store.dispatch('users/setLoading', false));
    });
  },

  removeMultiplySureUser(params,confirmDeleteDialog, confirmSureDeleteDialog) {
    console.log(params,"params")
    return new Promise((resolve, reject) => {
      store.dispatch('users/setLoading', true);
      http.delete('/api/admin/users/user/multiply', { params }).then((res) => {
        window.app.$notify({
          group: 'global',
          type: 'success',
          text: window.app.$t('message.admin.user_were_removed'),
        });
        confirmSureDeleteDialog.value = false
        resolve(res.data);
      }).catch((error) => {
        if (!error.response.data.isEvent) {
          HelpersErrors.showErrors(error.response.data.errors);
          confirmSureDeleteDialog.value = false
        }
        reject(error);
      }).finally(() => store.dispatch('users/setLoading', false));
    });
  },

  removeArchiveUser(id, confirmDeleteArchiveDialog) {
    return new Promise((resolve, reject) => {
      store.dispatch('users/setLoading', true);
      http.delete(`/api/admin/users/user/${id}`).then((res) => {
        window.app.$notify({
          group: 'global',
          type: 'success',
          text: window.app.$t('message.admin.user_was_removed'),
        });
        confirmDeleteArchiveDialog.value=false
        resolve(res.data);
      }).catch((error) => {
        if (error.response.data.isEvent) {
          HelpersErrors.showErrors(error.response.data.errors);
        }
        confirmDeleteArchiveDialog.value=false
        reject(error);
      }).finally(() => store.dispatch('users/setLoading', false));
    });
  },

  removeMultiplyArchiveUser(params,confirmDeleteArchiveDialog) {
    console.log(params,"params")
    return new Promise((resolve, reject) => {
      store.dispatch('users/setLoading', true);
      http.delete('/api/admin/users/user/multiply', { params }).then((res) => {
        window.app.$notify({
          group: 'global',
          type: 'success',
          text: window.app.$t('message.admin.user_were_removed'),
        });
        confirmDeleteArchiveDialog.value = false
        resolve(res.data);
      }).catch((error) => {
        console.log(123,error.response.data)
        if (error.response.data.isEvent) {
          HelpersErrors.showErrors(error.response.data.errors);
        }
        confirmDeleteArchiveDialog.value = false
        reject(error);
      }).finally(() => store.dispatch('users/setLoading', false));
    });
  },

  getArchiveUsers(params) {
    return new Promise((resolve, reject) => {
      store.dispatch('users/setLoading', true);
      http.get('/api/admin/users/archive', { params: { ...params, with: 'user_roles' } }).then((res) => {
        store.dispatch('users/setArchiveUsers', res.data);
        resolve(res.data);
      }).catch((error) => {
        HelpersErrors.showErrors(error.response.data.errors);
        reject(error);
      }).finally(() => store.dispatch('users/setLoading', false));
    });
  },
};
