import http from "@utils/http";
import store from "@store";

export default {
    getById(id) {
        store.dispatch("global/setAppLoading", true);
        return new Promise((resolve, reject) => {
            http.get(`/api/reservations/reservation/${id}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    store.dispatch("global/setAppLoading", false);
                });
        });
    },
    getEventsByMeta(eventMeta) {
        store.dispatch("global/setAppLoading", true);
        return new Promise((resolve, reject) => {
            http.get(`/api/event-meta/${eventMeta}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                })
                .finally(() => {
                    store.dispatch("global/setAppLoading", false);
                });
        });
    },
    create(formData) {
        return new Promise((resolve, reject) => {
            http.post("/api/event-meta/create", { ...formData }).then(
                ({ data }) => {
                    resolve(data);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },
    createRecurringEvents(formData) {
        return new Promise((resolve, reject) => {
            http.post("/api/event-meta/draft", { ...formData }).then(
                ({ data }) => {
                    resolve(data);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },
    undraftRecurringEvents(eventMeta) {
        return new Promise((resolve, reject) => {
            http.post(`/api/event-meta/submit/${eventMeta}`).then(
                ({ data }) => {
                    resolve(data);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    },
    edit(formData) {
        return new Promise((resolve, reject) => {
            http.put(`/api/reservations/reservation/${formData.id}`, {
                ...formData,
            })
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            http.delete(`/api/reservations/reservation/${id}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    removeMultiply(ids) {
        return new Promise((resolve, reject) => {
            http.delete("/api/reservations/reservation/multiply", {
                params: {
                    ids: [...ids],
                },
            })
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};
