import http from "@utils/http";
import store from "@store";
import HelpersErrors from "@helpers/errors";
export default {
    fetchData() {
        return new Promise((resolve, reject) => {
            http.get("/api/rooms")
                .then(({ data }) => {
                    store.dispatch("rooms/setRooms", data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    fetchAdminRooms(params) {
        return new Promise((resolve, reject) => {
            http.get("/api/admin/rooms", { params })
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateAdminRoomStatus(params) {
        return new Promise((resolve, reject) => {
            http.patch("/api/admin/rooms/room/update-room-availability", params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};
