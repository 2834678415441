import http from '@utils/http';
import store from '@store';
import HelpersErrors from '@helpers/errors';

export default {
  getGroup(id, params = {}) {
    return new Promise((resolve, reject) => {
      store.dispatch('group/setLoading', true);
      
      // Construct URL with proper parameters
      const searchQuery = params?.search_student_query?.target?.value || '';
      const url = `/api/admin/groups/${id}`;
      
      http.get(url, {
        params: {
          search_student_query: searchQuery,
          with: 'platform_students.user_roles' // Combined relationships as a single string
          // Remove platform_students, keep only group_students.user_roles
        }
      })
      .then((res) => {
        store.dispatch('group/setGroup', res.data.data);
        resolve(res.data);
      })
      .catch((error) => {
        HelpersErrors.showErrors(error.response.data.errors);
        reject(error);
      })
      .finally(() => {
        store.dispatch('group/setLoading', false)
      });
    });
  },
  getGroups(params) {
    return new Promise((resolve, reject) => {
      store.dispatch('groups/setLoading', true);
      http.get('/api/admin/groups', { 
        params: { 
          ...params, 
          with: 'platform_students.user_roles'
        } 
      }).then((res) => {
        store.dispatch('groups/setGroups', res.data);
        resolve(res.data);
      }).catch((error) => {
        HelpersErrors.showErrors(error.response.data.errors);
        reject(error);
      }).finally(() => store.dispatch('groups/setLoading', false));
    });
  },
  updateGroup(params, groupId) {
    return new Promise((resolve, reject) => {
      store.dispatch('group/setLoading', true);
      http.put(`/api/admin/groups/group/${groupId}`, params).then((res) => {
        window.app.$notify({
          group: 'global',
          type: 'success',
          text: window.app.$t('message.admin.group_was_updated'),
        });
        resolve(res.data);
      }).catch((error) => {
        HelpersErrors.showErrors(error.response.data.errors);
        reject(error);
      }).finally(() => store.dispatch('group/setLoading', false));
    });
  },
  createGroup(params) {
    return new Promise((resolve, reject) => {
      store.dispatch('group/setLoading', true);
      http.post('/api/admin/groups/group/create', params).then((res) => {
        window.app.$notify({
          group: 'global',
          type: 'success',
          text: window.app.$t('message.admin.user_was_created'),
        });
        resolve(res.data);
      }).catch((error) => {
        HelpersErrors.showErrors(error.response.data.errors);
        reject(error);
      }).finally(() => store.dispatch('group/setLoading', false));
    });
  },
  removeGroup(id, params) {
    return new Promise((resolve, reject) => {
      store.dispatch('groups/setLoading', true);
      http.delete(`/api/admin/groups/group/${id}`, { params }).then((res) => {
        window.app.$notify({
          group: 'global',
          type: 'success',
          text: window.app.$t('message.admin.group_was_removed'),
        });
        resolve(res.data);
      }).catch((error) => {
        HelpersErrors.showErrors(error.response.data.errors);
        reject(error);
      }).finally(() => store.dispatch('groups/setLoading', false));
    });
  },
  removeMultiplyGroup(params) {
    return new Promise((resolve, reject) => {
      store.dispatch('groups/setLoading', true);
      http.delete('/api/admin/groups/group/multiply', { params }).then((res) => {
        window.app.$notify({
          group: 'global',
          type: 'success',
          text: window.app.$t('message.admin.group_was_removed'),
        });
        resolve(res.data);
      }).catch((error) => {
        HelpersErrors.showErrors(error.response.data.errors);
        reject(error);
      }).finally(() => store.dispatch('groups/setLoading', false));
    });
  },
  getStudents(params = {}) {
    return new Promise((resolve, reject) => {
      store.dispatch('groups/setLoading', true);
      
      const url = '/api/admin/groups/students';
      const searchQuery = typeof params.search === 'string' ? params.search : '';
      
      http.get(url, {
        params: {
          search: searchQuery,
          with: 'user_roles'
        }
      })
      .then((res) => {
        store.dispatch('groups/setStudents', res.data.data);
        resolve(res.data);
      })
      .catch((error) => {
        HelpersErrors.showErrors(error.response.data.errors);
        reject(error);
      })
      .finally(() => {
        store.dispatch('groups/setLoading', false);
      });
    });
  },
  assignStudents(params, groupId) {
    return new Promise((resolve, reject) => {
      store.dispatch('group/setLoading', true);
      http.post(`/api/admin/groups/group/${groupId}/assign`, params).then((res) => {
        window.app.$notify({
          group: 'global',
          type: 'success',
          text: window.app.$t('message.admin.students_were_assigned'),
        });
        resolve(res.data);
      }).catch((error) => {
        HelpersErrors.showErrors(error.response.data.errors);
        reject(error);
      }).finally(() => store.dispatch('group/setLoading', false));
    });
  },
  detachStudents(params, groupId) {
    return new Promise((resolve, reject) => {
      store.dispatch('group/setLoading', true);
      http.post(`/api/admin/groups/group/${groupId}/detach`, params).then((res) => {
        window.app.$notify({
          group: 'global',
          type: 'success',
          text: window.app.$t('message.admin.students_were_detached'),
        });
        resolve(res.data);
      }).catch((error) => {
        HelpersErrors.showErrors(error.response.data.errors);
        reject(error);
      }).finally(() => store.dispatch('group/setLoading', false));
    });
  },
};
